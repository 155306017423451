import { List, Datagrid, TextField, DateField } from "react-admin";

export const NewsletterList = () => {
  return (
    <List resource="newsletter" pagination={false}>
      <Datagrid
        rowClick={false}
        sort={undefined}
        isRowSelectable={() => false}
        isRowExpandable={() => false}
      >
        <TextField source="id" label="ID" sortable={false} />
        <TextField source="email" label="Email" sortable={false} />
        <DateField
          source="createdAt"
          label="Cadastrado em"
          showTime
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};
