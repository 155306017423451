import React from "react";
import {
  BooleanInput,
  Create,
  SelectInput,
  SimpleForm,
  TextInput,
  email,
  required,
} from "react-admin";

export const UsersCreate = () => (
  <Create resource="users">
    <SimpleForm>
      <TextInput
        source="firstName"
        label="Nome"
        validate={[required()]}
        fullWidth
      />
      <TextInput
        source="lastName"
        label="Sobrenome"
        validate={[required()]}
        fullWidth
      />
      <TextInput
        source="email"
        label="Email"
        validate={[required(), email()]}
        fullWidth
      />
      <TextInput source="password" label="Senha" fullWidth />
      <TextInput
        source="company"
        validate={[required()]}
        label="Empresa Associada"
        fullWidth
      />
      <SelectInput
        source="role"
        choices={[
          { id: "admin", name: "Administrador" },
          { id: "user", name: "Usuário" },
        ]}
        validate={[required()]}
        fullWidth
      />
      <BooleanInput
        source="accepted"
        label="Acesso liberado"
        defaultValue={true}
        fullWidth
      />
    </SimpleForm>
  </Create>
);
