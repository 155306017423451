import {
  List,
  Datagrid,
  TextField,
  useRecordContext,
  FieldProps,
  useRefresh,
  useNotify,
  Button,
} from "react-admin";
import { API_URL, deleteBanner } from "../../api";

const ApiImageField: React.FC<FieldProps> = ({ source }) => {
  const record = useRecordContext();
  if (!source || !record) return null;

  return (
    <img
      src={`${API_URL}/api/file/download/${record[source]}`}
      crossOrigin=""
      alt="thumbnail"
      style={{ maxHeight: 150 }}
    />
  );
};

const RemoveBannerButton: React.FC = (props) => {
  const refresh = useRefresh();
  const notify = useNotify();

  return (
    <Button
      label={"Remover banner"}
      variant="contained"
      size="small"
      color="error"
      onClick={async () => {
        try {
          const { success } = await deleteBanner();
          if (success) {
            refresh();
            notify(`Banner desabilitado com sucesso.`, { type: "success" });
          } else {
            notify("Erro ao remover banner.", { type: "error" });
          }
        } catch (e) {
          notify("Erro ao remover banner.", { type: "error" });
        }
      }}
    />
  );
};

export const BannerList = () => {
  return (
    <List resource="banner" pagination={false}>
      <Datagrid
        rowClick={false}
        sort={undefined}
        isRowSelectable={() => false}
        isRowExpandable={() => false}
        bulkActionButtons={false}
      >
        <TextField source="title" label="Título" sortable={false} />
        <TextField source="link" label="Link" sortable={false} />
        <ApiImageField source="image" label="Imagem" sortable={false} />
        <RemoveBannerButton />
      </Datagrid>
    </List>
  );
};
