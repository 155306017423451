import { Admin, RaThemeOptions, Resource, defaultTheme } from "react-admin";
import authProvider from "./authProvider";
import UserIcon from "@mui/icons-material/People";
import ListIcon from "@mui/icons-material/ListAlt";
import EventosIcon from "@mui/icons-material/CalendarMonth";
import NewsletterIcon from "@mui/icons-material/Mail";
import ThumbsIcon from "@mui/icons-material/Image";
import { UsersList } from "./resources/users/List";
import dataProvider from "./dataProvider";
import { NewsletterList } from "./resources/newsletter/List";
import { ThumbsList } from "./resources/thumbs/List";
import { BannerList } from "./resources/banner/List";
import { AppLayout } from "./layout";
import { ThumbsCreate } from "./resources/thumbs/Create";
import { BannerCreate } from "./resources/banner/Create";
import { UsersCreate } from "./resources/users/Create";
import { EventsList } from "./resources/events/List";
import { EventsCreate } from "./resources/events/Create";
import { AttendeesList } from "./resources/attendees/List";
import { EventsEdit } from "./resources/events/Edit";
import { HomeOrderList } from "./resources/homeorder/List";
import { HomeOrderEdit } from "./resources/homeorder/Edit";

const theme: RaThemeOptions = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#102C60",
    },
  },
  typography: {
    ...defaultTheme.typography,
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "Urbanist",
      "Roboto",
      "-apple-system",
      "BlinkMacSystemFont",
      "Arial",
      "sans-serif",
    ].join(","),
  },
};

const App = () => (
  <Admin
    dataProvider={dataProvider}
    authProvider={authProvider}
    theme={theme}
    layout={AppLayout}
  >
    <Resource
      name="users"
      list={UsersList}
      create={UsersCreate}
      options={{ label: "Usuários" }}
      icon={UserIcon}
    />
    <Resource
      name="events"
      list={EventsList}
      create={EventsCreate}
      edit={EventsEdit}
      options={{ label: "Eventos" }}
      icon={EventosIcon}
    />
    <Resource
      name="attendee"
      list={AttendeesList}
      options={{ label: "Inscrições em Eventos" }}
      icon={ListIcon}
    />
    <Resource
      name="newsletter"
      list={NewsletterList}
      options={{ label: "Newsletter" }}
      icon={NewsletterIcon}
    />
    <Resource
      name="thumbs"
      list={ThumbsList}
      create={ThumbsCreate}
      options={{ label: "Thumbnails" }}
      icon={ThumbsIcon}
    />
    <Resource
      name="banner"
      list={BannerList}
      create={BannerCreate}
      options={{ label: "Banner" }}
      icon={ThumbsIcon}
    />
    <Resource
      name="homeorder"
      list={HomeOrderList}
      edit={HomeOrderEdit}
      options={{ label: "Ordem Home" }}
      icon={ListIcon}
    />
  </Admin>
);

export default App;
