import { HttpError } from "react-admin";
import { loginUser } from "./api";
import { LoginResponse } from "../types/auth";

const authProvider = {
  login: async ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => {
    const response = await loginUser({ email: username, password });
    if (response.user.role !== "admin") {
      throw new Error("Acesso autorizado apenas a administradores.");
    }

    localStorage.setItem("auth", JSON.stringify(response));
    return response;
  },
  checkAuth: () =>
    localStorage.getItem("auth")
      ? Promise.resolve()
      : Promise.reject({ redirectTo: "/login" }),
  checkError: (error: HttpError) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("auth");
      return Promise.reject({ redirectTo: "/login" });
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  getPermissions: () => {
    // Required for the authentication to work
    return Promise.resolve();
  },
  logout: () => {
    localStorage.removeItem("auth");
    return Promise.resolve();
  },
  getIdentity: () => {
    try {
      const {
        user: { email, firstName, lastName },
      } = JSON.parse(localStorage.getItem("auth")!) as LoginResponse;
      return Promise.resolve({
        id: email,
        fullName: `${firstName} ${lastName}`,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default authProvider;
