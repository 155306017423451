/* eslint-disable indent */
import {
  CreateResult,
  DataProvider,
  DeleteResult,
  GetListResult,
  UpdateResult,
  withLifecycleCallbacks,
} from "react-admin";
import {
  createEvent,
  createThumb,
  createUser,
  deleteBanner,
  editEvent,
  getBanner,
  getBlogArticles,
  getEventAttendees,
  getEvents,
  getHomeOrder,
  getNewsletters,
  getPaymentMethods,
  getThumbs,
  getUsers,
  getVitrineArticles,
  replaceBanner,
  replaceHomeOrder,
  uploadFile,
} from "./api";
import { Banner, HomeOrderResponse } from "../types/banner";
import sortBy from "lodash/sortBy";
import unionBy from "lodash/unionBy";
import FuzzySearch from "fuzzy-search";

export type Resources =
  | "blog"
  | "vitrine"
  | "meiospagamento"
  | "file"
  | "banner"
  | "thumb"
  | "users";

const makeSortable =
  <T>(sort?: { field: string; order: string }) =>
  (data: T[]): T[] => {
    if (!sort) {
      return data;
    }

    const sortedData = sortBy(data, [sort.field]);

    if (sort.order === "DESC") {
      sortedData.reverse();
    }

    return sortedData;
  };

const makeFilterable =
  <T extends object>(keyField: string, filter?: Record<string, any>) =>
  (data: T[]): T[] => {
    const fields = Object.keys(filter ?? {});

    if (fields.length === 0) {
      return data;
    }

    const searcher = new FuzzySearch(data, fields, {
      caseSensitive: false,
    });
    const results = fields.reduce((acc, field) => {
      return unionBy(acc, searcher.search(filter![field]), keyField);
    }, [] as T[]);
    return results;
  };

const makeFormatted = <T>(data: T[]) => ({
  data,
  total: data.length,
});

const dataProvider: DataProvider = withLifecycleCallbacks(
  {
    getList: (resource: string, params): Promise<GetListResult> => {
      if (resource === "blog") {
        return getBlogArticles().then(makeFormatted);
      } else if (resource === "meiospagamento") {
        return getPaymentMethods().then(makeFormatted);
      } else if (resource === "vitrine") {
        return getVitrineArticles().then(makeFormatted);
      } else if (resource === "users") {
        return getUsers()
          .then(makeFilterable("email", params.filter))
          .then((d) => d.map((u) => ({ id: u.email, ...u })))
          .then(makeSortable(params.sort))
          .then(makeFormatted);
      } else if (resource === "newsletter") {
        return getNewsletters().then(makeFormatted);
      } else if (resource === "thumbs") {
        return getThumbs()
          .then((d) => d.map((t) => ({ id: t._id, ...t })))
          .then(makeFormatted);
      } else if (resource === "banner") {
        return getBanner()
          .then((d) => (d.banner ? [{ id: 0, ...d.banner }] : []))
          .then(makeFormatted);
      } else if (resource === "events") {
        return getEvents()
          .then((d) => d.map((e) => ({ ...e, id: e._id })))
          .then(makeFormatted);
      } else if (resource === "attendee") {
        return getEventAttendees()
          .then((d) => d.map((e) => ({ ...e, id: e._id })))
          .then(makeFilterable("email", params.filter))
          .then(makeFormatted);
      } else if (resource === "homeorder") {
        return getHomeOrder()
          .then((d) => (d.order ? [{ id: 0, ...d }] : []))
          .then(makeFormatted);
      } else {
        throw new Error("Not supported");
      }
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    getOne: (resource, params) => {
      if (resource === "events") {
        return getEvents()
          .then((d) => d.find((e) => e._id === params.id))
          .then((e) => ({ data: e ? { ...e, id: e._id } : null }));
      } else if (resource === "homeorder") {
        return getHomeOrder().then((d) => ({ data: { id: 0, ...d } }));
      }
      throw new Error("Not supported");
    },

    getMany: () => {
      throw new Error("Not supported");
    },

    getManyReference: () => {
      throw new Error("Not supported");
    },

    create: (resource, params): Promise<CreateResult> => {
      if (resource === "users") {
        return createUser(params.data).then((user) => ({
          data: { ...user, id: user._id },
        }));
      }
      if (resource === "thumbs") {
        return createThumb(params.data.image, params.data.link).then(
          (thumb) => ({ data: { ...thumb, id: thumb._id } }),
        );
      }
      if (resource === "banner") {
        return replaceBanner(params.data).then((data) => ({
          data: { ...data, id: data._id },
        }));
      }
      if (resource === "events") {
        return createEvent(params.data).then((data) => ({
          data: { ...data, id: data._id },
        }));
      } else {
        throw new Error("Not supported");
      }
    },

    update: (resource, params): Promise<UpdateResult> => {
      if (resource === "banner") {
        return replaceBanner(params.data as Banner).then((data) => ({ data }));
      }
      if (resource === "homeorder") {
        return replaceHomeOrder(params.data.order as any[]).then((data) => ({
          data: { ...data, id: 0 },
        }));
      }
      if (resource === "events") {
        const event = { ...params.data };
        delete event.id;
        delete event._id;
        delete event.createdAt;
        delete event.updatedAt;
        delete event.__v;
        return editEvent(params.id, event).then((data) => ({
          data: { ...data, id: data._id },
        }));
      } else {
        throw new Error("Not supported");
      }
    },

    updateMany: (resource, params) => {
      throw new Error("Not supported");
    },

    delete: (resource, params): Promise<DeleteResult> => {
      if (resource === "banner") {
        return deleteBanner().then((data) => ({ data }));
      } else {
        throw new Error("Not supported");
      }
    },

    deleteMany: (resource, params) => {
      throw new Error("Not supported");
    },
  },
  [
    {
      resource: "thumbs",
      beforeCreate: async (params) => {
        const image: File | undefined = params.data.image?.rawFile;

        let imageName: string | undefined = undefined;
        if (image) {
          const result = await uploadFile(image);
          console.log("upload result", result);
          imageName = result.file.filename;
        }

        return {
          ...params,
          data: {
            ...params.data,
            image: imageName,
          },
        };
      },
    },
    {
      resource: "banner",
      beforeCreate: async (params) => {
        const image: File | undefined = params.data.image?.rawFile;

        let imageName: string | undefined = undefined;
        if (image) {
          const result = await uploadFile(image);
          imageName = result.file.filename;
        }

        return {
          ...params,
          data: {
            ...params.data,
            image: imageName,
          },
        };
      },
    },
    {
      resource: "events",
      beforeCreate: async (params) => {
        const images = params.data.images ?? [];
        const resultImagesArray = [];
        for (const image of images) {
          if (image?.rawFile) {
            const result = await uploadFile(image!.rawFile);
            resultImagesArray.push(result.file.filename);
          } else if (
            typeof image === "string" &&
            image.startsWith("https://")
          ) {
            resultImagesArray.push(image);
          }
        }
        return {
          ...params,
          data: {
            ...params.data,
            images: resultImagesArray,
          },
        };
      },
    },
  ],
);

export default dataProvider;
