import React from "react";
import {
  Create,
  FileField,
  FileInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";

export const BannerCreate = () => (
  <Create resource="banner">
    <SimpleForm>
      <TextInput
        source="title"
        label="Título"
        validate={[required()]}
        fullWidth
      />
      <FileInput
        source="image"
        label="Imagem"
        accept="image/*"
        validate={[required()]}
        fullWidth
      >
        <FileField source="src" title="title" />
      </FileInput>
      <TextInput source="link" label="Link" validate={[required()]} fullWidth />
    </SimpleForm>
  </Create>
);
