import {
  List,
  Datagrid,
  TextField,
  useRecordContext,
  FieldProps,
  DateField,
  BooleanField,
  EditButton,
} from "react-admin";
import { API_URL } from "../../api";
import { Grid } from "@mui/material";

export const ApiImageField: React.FC<FieldProps> = ({ source }) => {
  const record = useRecordContext();
  if (!source || !record) return null;

  const images = record[source] ?? [];

  return (
    <Grid container direction="row" spacing={2} sx={{ height: "100%" }}>
      {images.map((image: string) => (
        <Grid item>
          <img
            src={
              image.startsWith("https://")
                ? image
                : `${API_URL}/api/file/download/${image}`
            }
            crossOrigin=""
            alt="Image"
            style={{
              maxHeight: 100,
              maxWidth: 100,
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export const EventsList = () => {
  return (
    <List resource="events" pagination={false}>
      <Datagrid
        rowClick={false}
        sort={undefined}
        isRowSelectable={() => false}
        isRowExpandable={() => false}
      >
        <ApiImageField source="images" label="Imagem" />
        <TextField source="name" label="Nome" sortable={false} />
        <DateField
          showTime
          source="initialDate"
          label="Data Inicial"
          sortable={false}
        />
        <DateField
          showTime
          source="endDate"
          label="Data Final"
          sortable={false}
        />
        <BooleanField source="isEventbriteEvent" label="Eventbrite" />
        <BooleanField source="visible" label="Visível" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
