import React from "react";
import { TextField } from "@mui/material";
import InputMask, { Props as InputMaskProps } from "react-input-mask";
import { useInput } from "react-admin";

const MaskTextInput: React.FC<
  { required?: boolean; source: string; label: string } & InputMaskProps
> = ({ required, source, label, ...props }) => {
  const input = useInput({ source });

  const { isTouched, error } = input.fieldState;

  return (
    <InputMask
      error={!!(isTouched && error?.message)}
      helperText={isTouched && error?.message}
      {...input.field}
      {...props}
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
      {/*@ts-ignore*/}
      {() => (
        <TextField
          {...input.field}
          error={!!(isTouched && error?.message)}
          helperText={isTouched && error?.message}
          variant="filled"
          disabled={props.disabled}
          required={required}
          label={label + (required ? " *" : "")}
          fullWidth
        />
      )}
    </InputMask>
  );
};

export default MaskTextInput;
