import {
  List,
  Datagrid,
  TextField,
  Button,
  useRecordContext,
  useNotify,
  useRefresh,
  TextInput,
  SelectInput,
  useGetIdentity,
  DateField,
} from "react-admin";
import { User } from "../../../types/auth";
import {
  acceptUser,
  changeUserRole,
  disableUserEvent,
  enableUserEvent,
  revokeUser,
} from "../../api";

const EventManagerButton: React.FC = (props) => {
  const record = useRecordContext<User>(props);
  const refresh = useRefresh();
  const notify = useNotify();

  return (
    <Button
      label={record.eventManager ? "Desabilitar eventos" : "Habilitar eventos"}
      variant="outlined"
      size="small"
      onClick={async () => {
        try {
          let success = false;
          if (record.eventManager) {
            const result = await disableUserEvent(record.email);
            success = result.success;
          } else {
            const result = await enableUserEvent(record.email);
            success = result.success;
          }

          if (success) {
            refresh();
            notify(
              record.eventManager
                ? "Acesso à área de eventos revogado."
                : "Acesso à área de eventos liberado.",
              {
                type: "success",
              },
            );
          } else {
            notify("Erro ao modificar acesso.", { type: "error" });
          }
        } catch (e) {
          notify("Erro ao modificar acesso.", { type: "error" });
        }
      }}
    />
  );
};

const AcceptButton: React.FC = (props) => {
  const record = useRecordContext<User>(props);
  const refresh = useRefresh();
  const notify = useNotify();
  const { data: user } = useGetIdentity();

  return (
    <Button
      label={record.accepted ? "Revogar Acesso" : "Liberar acesso"}
      variant="outlined"
      size="small"
      onClick={async () => {
        try {
          let success = false;
          if (record.accepted) {
            if (record.email === user?.id) {
              notify("Você não pode revogar seu próprio acesso.", {
                type: "error",
              });
              return;
            }
            const result = await revokeUser(record.email);
            success = result.success;
          } else {
            const result = await acceptUser(record.email);
            success = result.success;
          }

          if (success) {
            refresh();
            notify(record.accepted ? "Acesso revogado." : "Acesso liberado.", {
              type: "success",
            });
          } else {
            notify("Erro ao liberar acesso.", { type: "error" });
          }
        } catch (e) {
          notify("Erro ao liberar acesso.", { type: "error" });
        }
      }}
    />
  );
};

const ChangeRoleButton: React.FC = (props) => {
  const record = useRecordContext<User>(props);
  const refresh = useRefresh();
  const notify = useNotify();
  const { data: user } = useGetIdentity();

  return (
    <Button
      label={
        "Tornar " +
        (record.role === "admin" ? "usuário regular" : "administrador")
      }
      variant="outlined"
      size="small"
      onClick={async () => {
        try {
          if (record.email === user?.id && record.role === "admin") {
            notify("Você não pode rebaixar seu próprio nível de acesso.", {
              type: "error",
            });
            return;
          }
          const { success } = await changeUserRole(
            record.email,
            record.role === "admin" ? "user" : "admin",
          );
          if (success) {
            refresh();
            notify(
              `Usuário ${record.email} alterado para ${
                record.role === "admin" ? "user" : "admin"
              } com sucesso.`,
              { type: "success" },
            );
          } else {
            notify("Erro ao alterar nível de acesso.", { type: "error" });
          }
        } catch (e) {
          notify("Erro ao alterar nível de acesso.", { type: "error" });
        }
      }}
    />
  );
};

const filters = [
  <TextInput label="Sobrenome" source="lastName" />,
  <TextInput label="Email" source="email" />,
  <TextInput label="Associado" source="company" />,
  <SelectInput
    label="Nível de Acesso"
    source="role"
    choices={[
      { id: "admin", name: "Admin" },
      { id: "user", name: "Usuário" },
    ]}
  />,
];

export const UsersList = () => {
  return (
    <List resource="users" filters={filters} pagination={false}>
      <Datagrid
        rowClick={false}
        sort={undefined}
        isRowSelectable={() => false}
        isRowExpandable={() => false}
      >
        <TextField source="firstName" label="Nome" />
        <TextField source="lastName" label="Sobrenome" />
        <TextField source="email" label="Email" />
        <TextField source="company" label="Associado" />
        <TextField source="role" label="Nível de acesso" />
        <DateField source="createdAt" label="Cadastrado em" showTime />
        <ChangeRoleButton />
        <EventManagerButton />
        <AcceptButton />
      </Datagrid>
    </List>
  );
};
