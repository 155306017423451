import { List, Datagrid, TextField, DateField } from "react-admin";

export const AttendeesList = () => {
  return (
    <List resource="attendee" pagination={false}>
      <Datagrid
        rowClick={false}
        sort={undefined}
        isRowSelectable={() => false}
        isRowExpandable={() => false}
      >
        <TextField source="firstName" label="Nome" sortable={false} />
        <TextField source="lastName" label="Sobrenome" sortable={false} />
        <TextField source="email" label="Email" sortable={false} />
        <TextField source="status" label="Status" sortable={false} />
        <DateField
          source="createdAt"
          label="Cadastrado em"
          showTime
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};
