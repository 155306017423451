import {
  List,
  Datagrid,
  TextField,
  ArrayField,
  EditButton,
  BooleanField,
} from "react-admin";

export const HomeOrderList = () => {
  return (
    <List resource="homeorder" pagination={false}>
      <Datagrid
        rowClick={false}
        sort={undefined}
        isRowSelectable={() => false}
        isRowExpandable={() => false}
        bulkActionButtons={false}
        header={() => null}
      >
        <ArrayField source="order" label="Ordem das Seções da Home" fullWidth>
          <Datagrid bulkActionButtons={false}>
            <TextField source="name" label="Seção" />
            <BooleanField source="visible" label="Visível" />
          </Datagrid>
        </ArrayField>

        <EditButton label="Alterar" />
      </Datagrid>
    </List>
  );
};
