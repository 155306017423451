import React from "react";
import {
  ArrayInput,
  BooleanInput,
  Edit,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import { Typography } from "@mui/material";

export const HomeOrderEdit = () => (
  <Edit resource="homeorder">
    <SimpleForm>
      <Typography variant="h6" gutterBottom>
        Ordem das Seções da Home
      </Typography>
      <ArrayInput source="order" fullWidth label={false}>
        <SimpleFormIterator inline disableAdd disableRemove>
          <TextInput source="name" helperText={false} disabled label="Seção" />
          <BooleanInput source="visible" defaultValue={true} label="Visível" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);
