import {
  List,
  Datagrid,
  TextField,
  useRecordContext,
  FieldProps,
  useRefresh,
  useNotify,
  Button,
} from "react-admin";
import { API_URL, deleteThumb } from "../../api";
import { Thumb } from "../../../types/thumb";

const ApiImageField: React.FC<FieldProps> = ({ source }) => {
  const record = useRecordContext();
  if (!source || !record) return null;

  return (
    <img
      src={`${API_URL}/api/file/download/${record[source]}`}
      crossOrigin=""
      alt="thumbnail"
    />
  );
};

const RemoveThumbButton: React.FC = (props) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const record = useRecordContext<Thumb>(props);

  return (
    <Button
      label={"Remover thumb"}
      variant="contained"
      size="small"
      color="error"
      onClick={async () => {
        try {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-expect-error
          const { success } = await deleteThumb(record._id ?? record.id);
          if (success) {
            refresh();
            notify(`Thumb removida com sucesso.`, { type: "success" });
          } else {
            notify("Erro ao remover thumb.", { type: "error" });
          }
        } catch (e) {
          notify("Erro ao remover thumb.", { type: "error" });
        }
      }}
    />
  );
};

export const ThumbsList = () => {
  return (
    <List resource="thumbs" pagination={false}>
      <Datagrid
        rowClick={false}
        sort={undefined}
        isRowSelectable={() => false}
        isRowExpandable={() => false}
      >
        <TextField source="id" label="ID" sortable={false} />
        <ApiImageField source="image" label="Imagem" sortable={false} />
        <TextField source="link" label="Link" sortable={false} />
        <RemoveThumbButton />
      </Datagrid>
    </List>
  );
};
